import React, { FC } from 'react';
import Logo from '../../assets/headerLogo.png';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useLanguage } from '../../hooks/useLanguage';
import { LANGUAGE_OPTIONS, LANG_TEXT } from '../../lang/community';
import { Languages } from './Languages';
import { MobileMenu } from './MobileMenu';
import { scrollToTop } from '../../utils/utils';

type HeaderProps = {
	isMenuOpen: boolean;
	setIsMenuOpen: (value: boolean) => void;
};

export const Header: FC<HeaderProps> = ({ isMenuOpen, setIsMenuOpen }) => {
	const language = useLanguage();
	const isUkr = language === LANGUAGE_OPTIONS[0];
	const homePageRoute = `/${isUkr ? '' : language + '/'}`;
	const mentorshipPageRoute = `${homePageRoute}mentorship`;
	const resultsPageRoute = `${homePageRoute}results`;
	const indicatorsPageRoute = `${homePageRoute}indicators`;

	return (
		<HeaderWrap>
			<HeaderContent>
				<LogoWrap
					draggable="false"
					to={homePageRoute}
					onClick={() => {
						setIsMenuOpen(false);
						scrollToTop();
					}}
				>
					<img draggable="false" src={Logo} alt="Logo" />
				</LogoWrap>

				<DesktopNav>
					<NavLink draggable="false" to={homePageRoute} onClick={scrollToTop}>
						{LANG_TEXT[language].header.community}
					</NavLink>
					<NavLink
						draggable="false"
						to={mentorshipPageRoute}
						onClick={scrollToTop}
					>
						{LANG_TEXT[language].header.mentorship}
					</NavLink>
					<NavLink
						draggable="false"
						to={resultsPageRoute}
						onClick={scrollToTop}
					>
						{LANG_TEXT[language].header.results}
					</NavLink>
					<NavLink
						draggable="false"
						to={indicatorsPageRoute}
						onClick={scrollToTop}
					>
						{LANG_TEXT[language].header.indicators}
					</NavLink>
				</DesktopNav>
				<DesktopNav>
					<Languages isUkr={isUkr} language={language} />
				</DesktopNav>
				<MobileNav
					onClick={() => setIsMenuOpen(!isMenuOpen)}
					isMenuOpen={isMenuOpen}
				>
					<MenuItem />
					<MenuItem />
					<MenuItem />
				</MobileNav>
			</HeaderContent>
			<MobileMenu
				homePageRoute={homePageRoute}
				mentorshipPageRoute={mentorshipPageRoute}
				resultsPageRoute={resultsPageRoute}
				indicatorsPageRoute={indicatorsPageRoute}
				isMenuOpen={isMenuOpen}
				isUkr={isUkr}
				language={language}
				closeMenu={() => setIsMenuOpen(false)}
			/>
		</HeaderWrap>
	);
};

const HeaderWrap = styled.div`
	z-index: 1000;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 130px;
	background: ${(p) => p.theme.blackBg};

	@media (max-width: 950px) {
		height: 100px;
	}
`;

const HeaderContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: ${(p) => p.theme.screenWidth};
	padding: 20px 15px;
	background: ${(p) => p.theme.blackBg};

	@media (max-width: 950px) {
		padding: 5px 15px;
	}
`;

const LogoWrap = styled(Link)`
	width: 200px;

	img {
		width: 100%;
	}
`;

const NavLink = styled(Link)<{ selected?: boolean }>`
	margin: 20px;
	color: ${(p) => p.theme.whiteText};
	text-decoration: none;
	transition: 0.1s;
	padding: 3px;
	font-family: 'Roboto Slab', serif;

	&:hover {
		background: ${(p) => p.theme.red};
		color: ${(p) => p.theme.blackText};
	}
`;

const DesktopNav = styled.div`
	@media (max-width: 950px) {
		display: none;
		visibility: hidden;
	}
`;

const MobileNav = styled.div<{ isMenuOpen: boolean }>`
	height: 25px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	transform: ${({ isMenuOpen }) => (isMenuOpen ? 'translateX(10px)' : '')};
	transition: 0.2s;

	> div {
		&:nth-of-type(1) {
			transform: ${({ isMenuOpen }) =>
				isMenuOpen ? 'rotate(45deg) translateY(17px)' : ''};
		}
		&:nth-of-type(3) {
			transform: ${({ isMenuOpen }) =>
				isMenuOpen ? 'rotate(-45deg) translateY(-16px)' : ''};
		}
		&:nth-of-type(2) {
			transition: 0.25s;
			width: 25px;
			transform: ${({ isMenuOpen }) =>
				isMenuOpen ? ' translateX(-16px) scale(0)' : ''};
		}
	}

	@media (min-width: 951px) {
		display: none;
		visibility: hidden;
	}
`;

const MenuItem = styled.div`
	width: 40px;
	height: 2px;
	background: ${(p) => p.theme.whiteText};
	transition: 0.1s;
`;
