import React from 'react';
import ReactDOM from 'react-dom/client';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './ErrorRoute';
import Root from './root/Root';
import { Community } from './components/Community/Community';
import { Mentorship } from './components/Mentorship/Mentorship';
import { Results } from './components/Results/Results';
import { Telegram } from './components/Telegram';
import { Indicators } from './components/Indicators/Indicators';
import { TELEGRAM_LINKS } from './lang/redirects';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: '/',
				element: <Community />,
			},
			{
				path: '/mentorship',
				element: <Mentorship />,
			},
			{
				path: '/results',
				element: <Results />,
			},
			{
				path: '/indicators',
				element: <Indicators />,
			},
			{
				path: '/ru',
				element: <Community />,
			},
			{
				path: '/ru/mentorship',
				element: <Mentorship />,
			},
			{
				path: '/ru/results',
				element: <Results />,
			},
			{
				path: '/ru/indicators',
				element: <Indicators />,
			},
			{
				path: '/en',
				element: <Community />,
			},
			{
				path: '/en/mentorship',
				element: <Mentorship />,
			},
			{
				path: '/en/results',
				element: <Results />,
			},
			{
				path: '/en/indicators',
				element: <Indicators />,
			},
			{
				path: '/telegram',
				element: <Telegram link={TELEGRAM_LINKS.goshawty} />,
			},
			{
				path: '/channel',
				element: <Telegram link={TELEGRAM_LINKS.goshawtyDiscord} />,
			},
			{
				path: '/dxdy0x',
				element: <Telegram link={TELEGRAM_LINKS.dxdy0x} />,
			},
			{
				path: '/cage',
				element: <Telegram link={TELEGRAM_LINKS.cage} />,
			},
			{
				path: '/vladikk317',
				element: <Telegram link={TELEGRAM_LINKS.vladikk317} />,
			},
			{
				path: '/valentaizer',
				element: <Telegram link={TELEGRAM_LINKS.valentaizer} />,
			},
			{
				path: '/bu1bazawr',
				element: <Telegram link={TELEGRAM_LINKS.bu1bazawr} />,
			},
			{
				path: '/yankie',
				element: <Telegram link={TELEGRAM_LINKS.yankie} />,
			},
			{
				path: '/vicione',
				element: <Telegram link={TELEGRAM_LINKS.vicione} />,
			},
		],
	},
]);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
